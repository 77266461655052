.custom-solutions-container {
  display: flex;
  padding: 0rem 1rem;
  padding-bottom: 1rem;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
}

.custom-solutions-title {
  display: flex;
  padding-top: 32px;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
  color: var(--Subtitles);
  font-size: 2.5rem;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.custom-solutions-image-wrapper {
  display: flex;
  padding: 2rem 0rem 1.5rem 0rem;
  align-items: center;
  justify-content: center;
  align-content: flex-start;
  align-self: stretch;
  flex-direction: column;
}

.custom-solutions-image {
  display: flex;
  height: 10rem;
  justify-content: end;
  flex-direction: column;
  align-items: flex-start;
  background-size: cover;
  max-width: 21rem;
  width: 100%;
}

.custom-solutions-image-text {
  color: var(--Text-on-Images---Colors);
  font-size: 1.3125rem;
  font-style: normal;
  font-weight: 800;
  line-height: 93.188%;
  padding: 1rem;
  -webkit-hyphens: auto;
  -moz-hyphens: auto;
  -ms-hyphens: auto;
  hyphens: auto;
}

.custom-solutions-operator-image {
  display: flex;
  padding: 2rem;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.custom-solutions-image.product {
  background-image: url(/src/assets/Rectangle19.png);
}

.custom-solutions-image.upgrade {
  background-image: url(/src/assets/Rectangle20.png);
}

.custom-solutions-image.solutions {
  background-image: url(/src/assets/Rectangle21.png);
}

.custom-solutions-body-text {
  color: var(--Body-Text-Gray-White);
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: 155.188%;
  padding-bottom: 1rem;
}

.custom-solutions-display-wrapper {
  display: flex;
}

.custom-solutions-processor-image {
  margin: 2.44rem 3rem 0rem 0rem;
  min-width: 22.5rem;
  min-height: 22.5rem;
  background-image: url(/src/assets/Processor.jpeg);
  background-size: cover;
  display: none;
}

@media only screen and (min-width: 910px) {
  .custom-solutions-image-wrapper {
    flex-direction: row;
    justify-content: space-between;
  }
}

@media only screen and (min-width: 1440px) {
  .custom-solutions-container {
    padding: 0rem;
    padding-bottom: 5rem;
    width: 73.5rem;
    align-self: initial;
  }

  .custom-solutions-image {
    max-width: 10rem;
  }

  .custom-solutions-processor-image {
    display: flex;
  }

  .custom-solutions-image-wrapper {
    justify-content: initial;
  }
}