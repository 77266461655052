.cookie-consent-button-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 2rem;
    align-items: center;
    justify-content: end;
}

.cookie-consent-settings-button {
    display: flex;
    padding: 0.75rem 2rem;
    justify-content: center;
    align-items: center;
    gap: 0.5rem;
    align-self: stretch;
    background-color: var(--Text-on-Button);
    color: var(--Button-Color---Default);
    -webkit-box-shadow: inset 0px 0px 0px 3px var(--Button-Color---Default);
    -moz-box-shadow: inset 0px 0px 0px 3px var(--Button-Color---Default);
    box-shadow: inset 0px 0px 0px 3px var(--Button-Color---Default);
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 700;
    line-height: 136.188%;
    cursor: pointer;
    transition: background-color ease 0.5s;
    -webkit-user-select: none;
    /* Safari */
    -ms-user-select: none;
    /* IE 10 and IE 11 */
    user-select: none;
    /* Standard syntax */
}

.cookie-consent-settings-button:hover {
    background-color: var(--Button-Color---Hover);
    color: var(--Text-on-Button);
}

.cookie-consent-accept-button {
    display: flex;
    padding: 0.75rem 2rem;
    justify-content: center;
    align-items: center;
    gap: 0.5rem;
    align-self: stretch;
    background-color: var(--Button-Color---Default);
    color: var(--Text-on-Button);
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 700;
    line-height: 136.188%;
    cursor: pointer;
    transition: background-color ease 0.5s;
    -webkit-user-select: none;
    /* Safari */
    -ms-user-select: none;
    /* IE 10 and IE 11 */
    user-select: none;
    /* Standard syntax */
}

.cookie-consent-accept-button:hover {
    background-color: var(--Button-Color---Hover);
}

.cookie-consent-header-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.cookie-consent-info {
    font-size: 1rem;
    font-weight: 400;
    margin-bottom: 1rem;
}

.cookie-consent-link {
    background: none !important;
    border: none;
    padding: 0 !important;
    /*optional*/
    font-family: arial, sans-serif;
    /*input has OS specific font-family*/
    color: #069;
    text-decoration: underline;
    cursor: pointer;
    font-size: 1.25rem;
}