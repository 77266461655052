.contact-us-main-container {
  background: var(--Contact---Mobile-BG);
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  -webkit-transform: scaleY(-1);
  transform: scaleY(-1);
}

.contact-us-container {
  padding: 1rem;
  padding-bottom: 2rem;
  display: flex;
  flex-direction: column;
  -webkit-transform: scaleY(-1);
  transform: scaleY(-1);
}

.contact-us-title {
  padding: 0.5rem 0rem 1.5rem 0rem;
  font-size: 2.5rem;
  color: var(--Text-on-Images---Colors);
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.contact-us-split-wrapper {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.contact-us-input-wrapper {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.contact-us-recapcha-container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 1.25rem;
}

.contact-us-inerest-title {
  color: var(--Label---Input-Field);
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  padding-top: 1.5rem;
}

.contact-us-interest-wrapper {
  padding: 1rem 0rem;
  display: flex;
  align-items: flex-start;
  align-content: flex-start;
  gap: 1rem;
  flex-wrap: wrap;
  align-self: stretch;
}

.contact-us-send-now-button {
  display: flex;
  padding: 0.75rem 2rem;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
  align-self: stretch;
  background-color: var(--Button-Color---Default);
  color: var(--Text-on-Button);
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 700;
  line-height: 136.188%;
  width: 100%;
  cursor: pointer;
  transition: background-color ease 0.5s;
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */
}

.contact-us-dialog-title {
  width: 300px;
}

.contact-us-cancel-button {
  display: flex;
  padding: 0.75rem 2rem;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
  align-self: stretch;
  background-color: var(--Text-on-Button);
  color: var(--Button-Color---Default);
  -webkit-box-shadow: inset 0px 0px 0px 3px var(--Button-Color---Default);
  -moz-box-shadow: inset 0px 0px 0px 3px var(--Button-Color---Default);
  box-shadow: inset 0px 0px 0px 3px var(--Button-Color---Default);
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 700;
  line-height: 136.188%;
  cursor: pointer;
  transition: background-color ease 0.5s;
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */
}

.contact-us-cancel-button:hover {
  background-color: var(--Button-Color---Hover);
  color: var(--Text-on-Button);
}

.contact-us-confirm-button {
  display: flex;
  padding: 0.75rem 2rem;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
  align-self: stretch;
  background-color: var(--Button-Color---Default);
  color: var(--Text-on-Button);
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 700;
  line-height: 136.188%;
  cursor: pointer;
  transition: background-color ease 0.5s;
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */
}

.contact-us-confirm-button:hover {
  background-color: var(--Button-Color---Hover);
}

.contact-us-send-now-button:hover {
  background-color: var(--Button-Color---Hover);
}

.contact-us-send-now-button.sending {
  background-color: var(--Button-Color---Hover);
}

.contact-us-send-now-button.delivered {
  background-color: var(--Button-Color---Hover);
}

.contact-us-send-now-button.error {
  background-color: var(--Button-Color---Hover);
}

@media only screen and (min-width: 910px) {
  .contact-us-main-container {
    background: url(/src/assets/Rectangle38.png);
    background-size: cover;
  }

  .contact-us-container {
    align-self: stretch;
  }

  .contact-us-send-now-button {
    width: initial;
    min-width: 6rem;
    margin-left: auto;
  }

  .contact-us-input-wrapper {
    flex: 1 1;
    gap: 2rem;
  }

  .contact-us-split-wrapper {
    flex-direction: row;
    gap: 4rem;
  }

  .contact-us-interest-wrapper {
    gap: 2rem;
  }
}

@media only screen and (min-width: 1440px) {
  .contact-us-container {
    width: 73.5rem;
    align-self: initial;
    padding: 7.75rem 1rem;
  }
}
