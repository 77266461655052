:root {
  --Big-Titles---H1: #f8f8f8;
  --Body-Text-Gray-White: #f8f8f8;
  --Subtitles: #f8f8f8;
  --InfoBox-Text: #fff;
  --InfoBox-Arrow: #fff;
  --Text-on-Images---Colors: #fff;
  --Text-on-Button: #fff;
  --Label---Input-Field: #e9e9e9;
  --Input-Field-Color: #f4f4f4;
  --Checkbox-BTN-Text: #eee;
  --CheckBox-Icon-Color: #f4f4f4;
  --Custom-Solutions-Black-BG: #000;
  --Full-Black-for-Header: #000;
  --InfoBox-BTN-GroovyBox---Default: #5ac171;
  --InfoBox-Arrow-BG-BTN-GroovyBox-Green: #4ea361;
  --InfoBox-Arrow-BG-BTN-GroovyBox---Dark-Green: #448352;
  --InfoBox-Hover-Stroke-Green: #4d8b58;
  --InfoBox-BTN-GroovySystem---Orange-Default: #f46f24;
  --InfoBox-Arrow-BG-BTN-GroovySystem-Orange: #e36219;
  --InfoBox-Arrow-BG-BTN-GroovySystem---Dark-Orange: #b52e11;
  --InfoBox-Hover-Orange: #aa4912;
  --InfoBox-BTN-GroovyControl---Blue: #4492ef;
  --InfoBox-Arrow-BG-BTN-GroovyControl-Blue: #2e81e2;
  --InfoBox-Arrow-BG-BTN-GroovyControl---Dark-Blue: #2768b4;
  --InfoBox-Blue-Stroke-Hover: #2460a7;
  --Contact---Mobile-BG: #ea3232;
  --Input-Field-Stroke---Hover: #ffa0a0;
  --Input-Field-Error: #f44336;
  --Checkbox-BTN-Border-Color: #ffa0a0;
  --Button-Color---Default: #ff4d42;
  --Button-Color---Hover: #622a26;
}
