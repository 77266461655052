.card-grid-container {
  width: 100%;
}

.card-grid-title {
  padding: 1rem 1rem 2rem 1rem;
  color: var(--Subtitles);
  font-size: 2.5rem;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.card-grid-wrapper {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  align-content: flex-start;
  gap: 1rem 1rem;
  align-self: stretch;
  flex-wrap: wrap;
  padding: 0rem 1rem;
}

@media only screen and (min-width: 1440px) {
  .card-grid-container {
    padding: 0rem;
    width: 73.5rem;
  }

  .card-grid-title {
    display: flex;
    height: 4.5rem;
    padding: 2rem 0rem 0.5rem 0rem;
    align-items: flex-start;
    align-self: stretch;
  }

  .card-grid-wrapper {
    padding: 0rem;
    gap: 0rem 3rem;
  }
}
