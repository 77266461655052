.custom-button-container {
  display: flex;
  padding: 0.75rem 1.5rem;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
  color: var(--Checkbox-BTN-Text);
  border: 1px solid var(--Checkbox-BTN-Border-Color);
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 300;
  line-height: 136.188%;
  cursor: pointer;
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */
}

.custom-button-container:hover {
  -webkit-box-shadow: inset 0px 0px 0px 2px var(--Checkbox-BTN-Border-Color);
  -moz-box-shadow: inset 0px 0px 0px 2px var(--Checkbox-BTN-Border-Color);
  box-shadow: inset 0px 0px 0px 2px var(--Checkbox-BTN-Border-Color);
}
