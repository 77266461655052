.description-title {
  color: var(--Big-Titles---H1);
  padding: 2rem 1rem 0rem 1rem;
  font-family: Roboto;
  font-size: 3rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  white-space: break-spaces;
}

.description-text {
  color: var(--Body-Text-Gray-White);
  padding: 2rem 1rem 1rem 1rem;
  font-family: Roboto;
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: 155.188%;
}

@media only screen and (min-width: 1440px) {
  .description-title {
    padding: 0rem;
    font-size: 4.5rem;
  }

  .description-text {
    padding: 2rem 0rem;
  }

  .description-container {
    width: 73.5rem;
    padding-top: 7.75rem;
  }
}
