.card-container {
  background-size: cover;
  width: 100%;
  display: flex;
  min-height: 22.5rem;
  flex: 1 0;
  flex-direction: column;
  align-items: flex-start;
  transition: all ease 0.5s;
}

.card-header {
  display: flex;
  padding: 1.5rem 2rem;
  flex: 1 0 0;
  align-self: stretch;
}

.card-logo {
  width: 10rem;
  height: 4rem;
}

.card-text {
  display: flex;
  padding: 0.5rem 2rem;
  align-items: flex-start;
  flex: 1 0 0;
  align-self: stretch;
  color: var(--InfoBox-Text);
  font-size: 1rem;
  font-style: normal;
  font-weight: 300;
  line-height: 136.188%;
}

.card-action-container {
  display: flex;
  padding: 0rem 2rem 2rem 2rem;
  align-items: flex-end;
  flex: 1 0 0;
  align-self: stretch;
}

.card-button {
  display: flex;
  padding: 0.5rem 1rem;
  border-radius: 0.0625rem;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
  color: var(--InfoBox-Text);
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 700;
  line-height: 136.188%;
  box-shadow: 0px 11px 24px -10px rgba(0, 0, 0, 0.25);
  cursor: pointer;
  transition: all ease 0.5s;
}

.card-arrow-icon-container {
  display: flex;
  width: 1.125rem;
  height: 1.125rem;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 0.0625rem;
}

.card-arrow-icon {
  width: 0.5625rem;
  flex-shrink: 0;
  stroke-width: 1px;
  stroke: var(--InfoBox-Arrow);
}

.card-button:hover {
  box-shadow: none;
}

.groovy-box-container {
  background-image: url(/src/assets/Rectangle35.png);
}

.groovy-box-container:hover {
  -webkit-box-shadow: inset 0px 0px 0px 5px var(--InfoBox-Hover-Stroke-Green);
  -moz-box-shadow: inset 0px 0px 0px 5px var(--InfoBox-Hover-Stroke-Green);
  box-shadow: inset 0px 0px 0px 5px var(--InfoBox-Hover-Stroke-Green);
}

.groovy-box-button {
  background: var(--InfoBox-BTN-GroovyBox---Default);
}

.groovy-box-icon-container {
  background: var(--InfoBox-Arrow-BG-BTN-GroovyBox-Green);
}

.card-button:hover .groovy-box-icon-container {
  background: var(--InfoBox-Arrow-BG-BTN-GroovyBox---Dark-Green);
}

.groovy-system-container {
  background-image: url(/src/assets/Rectangle36.png);
}

.groovy-system-container:hover {
  -webkit-box-shadow: inset 0px 0px 0px 5px var(--InfoBox-Hover-Orange);
  -moz-box-shadow: inset 0px 0px 0px 5px var(--InfoBox-Hover-Orange);
  box-shadow: inset 0px 0px 0px 5px var(--InfoBox-Hover-Orange);
}

.groovy-system-button {
  background: var(--InfoBox-BTN-GroovySystem---Orange-Default);
}

.groovy-system-icon-container {
  background: var(--InfoBox-Arrow-BG-BTN-GroovySystem-Orange);
}

.card-button:hover .groovy-system-icon-container {
  background: var(--InfoBox-Arrow-BG-BTN-GroovySystem---Dark-Orange);
}

.groovy-control-container {
  background-image: url(/src/assets/Rectangle37.png);
}

.groovy-control-container:hover {
  -webkit-box-shadow: inset 0px 0px 0px 5px var(--InfoBox-Blue-Stroke-Hover);
  -moz-box-shadow: inset 0px 0px 0px 5px var(--InfoBox-Blue-Stroke-Hover);
  box-shadow: inset 0px 0px 0px 5px var(--InfoBox-Blue-Stroke-Hover);
}

.groovy-control-button {
  background: var(--InfoBox-BTN-GroovyControl---Blue);
}

.groovy-control-icon-container {
  background: var(--InfoBox-Arrow-BG-BTN-GroovyControl-Blue);
}

.card-button:hover .groovy-control-icon-container {
  background: var(--InfoBox-Arrow-BG-BTN-GroovyControl---Dark-Blue);
}