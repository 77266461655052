.policy-main-container {
    word-break: break-word;
    display: flex;
    flex-direction: column;
    background-color: var(--Foundation-White-W300);
    padding: 1.5rem;
    gap: 2rem;
}

.policy-title {
    font-size: 2rem;
    margin-bottom: 3rem;
    font-weight: 700;
}

.policy-content-header {
    font-size: 1.5rem;
    font-weight: 700;
}

.policy-subtitle-container {
    display: flex;
    flex-direction: column;
    margin-bottom: 4rem;
}

.policy-subtitle-header {
    font-size: 1.5rem;
    margin: 0.25rem 1.5rem;
    font-weight: 500;
}

.policy-subtitle {
    font-size: 1.5rem;
    margin: 0.5rem 1.5rem 0 1.5rem;
    font-weight: 500;
}

.policy-text {
    font-size: 1rem;
    font-weight: 400;
}

.policy-text-m-0 {
    font-size: 1rem;
    font-weight: 400;
    margin: 0;
}

.policy-text-type {
    text-decoration: underline;
    font-style: italic;
}

.policy-text-info {
    margin: 0 1rem;
}

.policy-info-text-wrapper {
    margin: 1rem;
}

.policy-list-container {
    margin: 2rem 0;
    border: 1px solid;
}

.policy-list-container .policy-list-item:nth-child(odd) {
    background: lightgray;
}

.policy-list-item {
    padding: 0.75rem;
    display: flex;
    flex-direction: column;
}

.policy-list-header-text {
    padding: 0.5rem;
    font-weight: 700;
    font-size: 1.25rem;
}

.policy-list-key {
    font-size: 1.15rem;
    font-weight: 400;
    margin-right: 0.5rem;
    font-weight: 500;
}

.policy-list-text {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    padding: 0.5rem;
    font-size: 1rem;
    font-weight: 400;
}

.policy-list-expiry-text {
    display: flex;
    flex-direction: row;
    padding: 0.5rem;
    font-weight: 500;
    font-size: 1.15rem;
}

.policy-bullet-container {
    margin-top: -1.5rem;
}

.policy-bullet-text-container {
    display: flex;
    margin: 0.5rem 1rem;
    font-size: 1rem;
    font-weight: 400;
}

.policy-bullet-point {
    margin-right: 1rem;
}

@media only screen and (min-width: 768px) {
    .policy-main-container {
        padding: 3rem;
    }
}

@media only screen and (min-width: 1440px) {
    .policy-main-container {
        padding: 6rem;
    }
}