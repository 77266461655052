/* scrollIntoView function wouldn't work because of the header being sticky, thus scroll-margin-top is needed */
:root {
  --offset-height: 158px;
}

#root {
  height: 100vh;
}

.css-0 {
  display: flex;
  flex-direction: column;
  height: 100%;
}

@media screen and (min-width: 900px) {
  * {
    scroll-margin-top: var(--offset-height);
  }
}