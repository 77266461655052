.custom-input-container {
  display: flex;
  flex-direction: column;
  position: relative;
}

.custom-input-label {
  color: var(--Label---Input-Field);
  padding-bottom: 0.75rem;
}

.custom-input-error-message {
  color: var(--Input-Field-Error);
  position: absolute;
  top: 3.25rem;
  left: 1rem;
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */
  pointer-events: none;
  cursor: text;
}

.custom-input {
  align-self: stretch;
  flex: 1;
  border: none;
  outline: none;
  padding: 1rem;
  font-size: 1.5rem;
  resize: none;
  white-space: nowrap;
  background-color: var(--Input-Field-Color);
}

.custom-input.error {
  -webkit-box-shadow: inset 0px 0px 0px 4px var(--Input-Field-Error);
  -moz-box-shadow: inset 0px 0px 0px 4px var(--Input-Field-Error);
  box-shadow: inset 0px 0px 0px 4px var(--Input-Field-Error);
}

.custom-input:hover {
  -webkit-box-shadow: inset 0px 0px 0px 4px var(--Input-Field-Stroke---Hover);
  -moz-box-shadow: inset 0px 0px 0px 4px var(--Input-Field-Stroke---Hover);
  box-shadow: inset 0px 0px 0px 4px var(--Input-Field-Stroke---Hover);
}

.custom-input:focus {
  -webkit-box-shadow: inset 0px 0px 0px 4px var(--Input-Field-Stroke---Hover);
  -moz-box-shadow: inset 0px 0px 0px 4px var(--Input-Field-Stroke---Hover);
  box-shadow: inset 0px 0px 0px 4px var(--Input-Field-Stroke---Hover);
}

.custom-input-container.textarea {
  height: 100%;
}
