.terms-main-container {
  word-break: break-word;
  display: flex;
  flex-direction: column;
  background-color: var(--Foundation-White-W300);
  padding: 0 1.5rem;
  padding-bottom: 4rem;
}

.terms-title {
  font-size: 2rem;
  margin-top: 4rem;
  font-weight: 700;
}

.terms-subtext {
  font-size: 1.25rem;
  font-weight: 400;
  margin: 4rem 0;
}

.terms-header {
  font-size: 1.5rem;
  font-weight: 700;
}

.terms-subtitle-header {
  font-size: 1.5rem;
  font-weight: 500;
  margin: 0.25rem 1.5rem;
}

.terms-content-point-container {
  font-size: 1.5rem;
  font-weight: 700;
  margin: 3rem 1rem;
}

.terms-point-header {
  display: flex;
  flex-direction: row;
  gap: 2rem;
  margin: 1rem;
  font-size: 1.5rem;
  font-weight: 700;
}

.terms-point {
  display: flex;
  flex-direction: row;
  gap: 2rem;
  margin: 1rem;
  font-size: 1.25rem;
  font-weight: 400;
}


@media only screen and (min-width: 768px) {
  .terms-main-container {
    padding: 0 3.5rem;
    padding-bottom: 4rem;
  }
}

@media only screen and (min-width: 1440px) {
  .terms-main-container {
    padding: 0 6rem;
    padding-bottom: 4rem;
  }
}