.container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;
}

.main-section {
  width: 100%;
  background: var(--Custom-Solutions-Black-BG);
  display: flex;
  flex-direction: column;
  align-items: center;
}

@media only screen and (min-width: 1440px) {
  .main-section {
    background-image: url(/src/assets/header-bg-image2.jpg);
    background-size: cover;
  }
}